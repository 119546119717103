<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Live Status</th>
          <th>Menu Present</th>
          <th>Auto Status</th>
          <th>Current Status</th>
          <th>Coupon Codes</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>IFSC</th>
          <th>Bank Account</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in restaurantList" :key="item._id">
          <td
            @click="
              () => {
                $router.push(`/edit-restraunt/${item._id}`);
              }
            "
          >
            {{ item.name }}
          </td>
          <td :className="[item.liveStatus === 'ACTIVE' ? 'true' : 'false']">
            {{ item.liveStatus }}
          </td>
          <td :className="[item.menuListPresent ? 'true' : 'false']">
            {{ item.menuListPresent }}
          </td>
          <td>{{ item.autoStatus }}</td>
          <td
            :className="[item.status === 'ONLINE' ? 'true' : 'false']"
            @click="
              () => {
                changeStatus(item.status, item._id);
              }
            "
          >
            {{ item.status }}
          </td>
          <td>{{ item.couponDataCode }}</td>
          <td>{{ item.startHour }}</td>
          <td>{{ item.endHour }}</td>
          <td>{{ item.ifsc }}</td>
          <td>{{ item.accountId }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restaurantList: [],
    };
  },
  async mounted() {
    this.restaurantList = await this.getApiCall(
      "/superAdmin/getRestaurantInfoList"
    );
  },
  methods: {
    async changeStatus(value, restaurantId) {
      await this.postApiCall("/superAdmin/setRestaurantStatus", {
        status: value === "ONLINE" ? "OFFLINE" : "ONLINE",
        restaurantId,
      });
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
/* styles.css */
table {
  width: 100%;
  border-collapse: collapse; /* Merges border lines for a cleaner look */
  margin: 20px 0; /* Adds margin around the table */
}

th,
td {
  border: 1px solid #dddddd; /* Light gray border */
  text-align: left; /* Aligns text to the left */
  padding: 8px; /* Adds padding inside cells */
}

thead {
  background-color: #f2f2f2; /* Light gray background for the header */
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternates row colors */
}

tr:hover {
  background-color: #ddd; /* Changes background color on hover */
}

.true {
  background-color: #d4edda; /* Greenish color for true */
}
.false {
  background-color: #f8d7da; /* Reddish color for false */
}
</style>
